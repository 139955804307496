// libs
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';

// redux actions
import {
  setHeaderOptions as _setHeaderOptions,
  resetHeaderOptions,
} from 'shared/store/app/actions';

// component
import Header, { IHPMobileHeaderProps } from './Header';

const mapDispatchToProps = {
  setHeaderOptions: _setHeaderOptions,
  resetHeaderOptions,
};

const fetchData = async ({ setHeaderOptions }: IHPMobileHeaderProps) => {
  setHeaderOptions({
    showSearch: false,
    showDiscount: false,
  });
};

export default connect(undefined, mapDispatchToProps)(frontloadConnect(fetchData)(Header));
