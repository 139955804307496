// libs
import React, { memo, useState, useEffect } from 'react';

// components
import SearchBar from 'shared/layouts/Homepage/HomePageMobile/components/SearchBar';

// contexts
import MobileHeaderContent from 'shared/components/Header/MobileHeaderProvider/MobileHeaderContent';

// redux actions
import {
  setHeaderOptions as _setHeaderOptions,
  resetHeaderOptions as _resetHeaderOptions,
} from 'shared/store/app/actions';

// styles
import { HeaderSearchBar } from './styles';
const IOOptions = { rootMargin: '-50px 0px 0px 0px' };

// defs
export interface IHPMobileHeaderProps {
  setHeaderOptions: typeof _setHeaderOptions;
  resetHeaderOptions: typeof _resetHeaderOptions;
  isWishlistEnabled: boolean;
}

function HPMobileHeader({
  setHeaderOptions,
  resetHeaderOptions,
  isWishlistEnabled,
}: IHPMobileHeaderProps) {
  const [showHeaderSearch, setShowHeaderSearch] = useState(false);

  useEffect(() => {
    setHeaderOptions({
      showSearch: false,
      showDiscount: false,
      showLogo: !showHeaderSearch,
    });

    () => {
      resetHeaderOptions();
    };
  }, [resetHeaderOptions, setHeaderOptions, showHeaderSearch]);

  const onSearchVisible = () => setShowHeaderSearch(false);
  const onSearchHidden = () => setShowHeaderSearch(true);
  return (
    <>
      {showHeaderSearch && (
        <MobileHeaderContent>
          <HeaderSearchBar data-test="header-searchbar" isWishlistEnabled={isWishlistEnabled} />
        </MobileHeaderContent>
      )}
      <SearchBar
        data-test="search-bar"
        onVisible={onSearchVisible}
        onHidden={onSearchHidden}
        IOOptions={IOOptions}
        doAnimate
        searchBarOnHeader={false}
        isWishlistEnabled={isWishlistEnabled}
      />
    </>
  );
}

export default memo(HPMobileHeader);
