import React, { memo } from 'react';

import Header from 'shared/layouts/Homepage/HomePageMobile/components/Header/Container';

interface IExperimentProps {
  isSearchBarEnabled: boolean;
  isWishlistEnabled: boolean;
}

function Experiment({ isSearchBarEnabled, isWishlistEnabled }: IExperimentProps) {
  if (isSearchBarEnabled === false) {
    return null;
  }

  return <Header isWishlistEnabled={isWishlistEnabled} />;
}

export default memo(Experiment);
