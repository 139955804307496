//static
export const LOGIN_TEXT = 'Login';
export const COPY_CODE = 'Copy Code';
export const WELCOME = 'Welcome Reward';
export const APP_DOWNLOAD_TEXT = 'Install';
export const SUCCESS_TOAST_MSG = 'Coupon code copied successfully';
export const ERROR_TOAST_MSG = 'Copying coupon code failed!';
//steps count
export const TOTAL_STEPS = 3;
//images
export const ICON_WIDTH = 38;
export const ICON_ASPECT_RATIO = 1;
export const ICON_ALT = 'Coupon';
export const COUPON_SRC =
  'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/Coupon.svg';
export const LOCKSTATE_SRC =
  'https://images-static.nykaa.com/nykdesignstudio-images/pub/media/fashion-images/gamification-lock.svg';

//experiment
export const GAMIFICATION_NUDGE = 'gamification-nudge';
export const GAMIFICATION_NUDGE_VARIANT_A = 'gamification-nudge-a';
export const DEFAULT_VALUE = -2;
export const GAMIFICATION_EVENTS = {
  GAMIFICATION_CTA_CLICK: 'Gamification_cta_click',
  IOC_STATE_MWEB: 'ioc_state_mweb',
};
export const GAMIFICATION_BUTTON_EXP = 'gamification-button-exp-ab';
export const GAMIFICATION_BUTTON_EXP_A = 'gamification-button-exp-a';