// libs
import get from 'lodash/get';

// constants
import { DEFAULT_CONFIG } from './constants';

interface ISearchConfig {
  searchEnabled: boolean;
  animationEnabled: boolean;
  staticPlaceholder: string;
  placeholderKeywords: string[];
  keywordPrefix: string;
}

export const isSearchBarEnabled = (config: ISearchConfig) =>
  get(config, 'searchEnabled', DEFAULT_CONFIG.searchEnabled);

export const isAnimationEnabled = (config: ISearchConfig) =>
  get(config, 'animationEnabled', DEFAULT_CONFIG.animationEnabled);

export const getStaticPlaceholer = (config: ISearchConfig) =>
  get(config, 'staticPlaceholder', DEFAULT_CONFIG.staticPlaceholder);

export const getPlaceholerKeywords = (config: ISearchConfig) =>
  get(config, 'placeholderKeywords', DEFAULT_CONFIG.placeholderKeywords);

export const getKeywordPrefix = (config: ISearchConfig) =>
  get(config, 'keywordPrefix', DEFAULT_CONFIG.keywordPrefix);
