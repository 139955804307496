// libs
import { connect } from 'react-redux';

// redux actions
import { setHeaderOptions } from 'shared/store/app/actions';

// component
import HomePageMobile from './HomePageMobile';

const mapDispatchToProps = {
  setHeaderOptions,
};

export default connect(null, mapDispatchToProps)(HomePageMobile);
