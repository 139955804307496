// libs
import React, { memo } from 'react';

// components
import { Mobile as DealsPlatformRenderer } from 'shared/components/DealsPlatformRenderer';
import AdPlatformWidgetsMobile from 'shared/components/AdPlatformWidgets/Mobile';

// hooks
import useDealsPlatformRedirect from 'shared/hooks/useDealsPlatformRedirect';

// constants
import { EAGER_IMAGE_LOADING_COUNT } from 'shared/constants';

// defs
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

// helpers
import logger from 'shared/logger';

interface DealsPlatformRender {
  landingPageData: ILandingPageProps['landingPageData'];
  pageName: string;
  eagerLoadingCount?: number;
}

function Mobile({
  landingPageData,
  pageName,
  eagerLoadingCount = EAGER_IMAGE_LOADING_COUNT,
}: DealsPlatformRender) {
  const shouldRenderWithDealsPlatform = useDealsPlatformRedirect(pageName);

  logger.info({
    information: 'AD_PLATFORM_VERSION',
    version: shouldRenderWithDealsPlatform ? 'V2' : 'V1',
    pageName,
    patform: 'mweb',
  });

  if (shouldRenderWithDealsPlatform) {
    return (
      <DealsPlatformRenderer
        landingPageData={landingPageData}
        pageName={pageName}
        eagerLoadingCount={eagerLoadingCount}
      />
    );
  }

  return <AdPlatformWidgetsMobile landingPageData={landingPageData} />;
}

export default memo(Mobile);
