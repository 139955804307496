// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// styles
const Banner = styled.div`
  padding-top: 50%;
  margin-bottom: ${({ theme }) => theme.spacing.spacing200};
  background: ${({ theme }) => theme.colors.surface20};
`;

const Title = styled.p`
  height: 18px;
  background: ${({ theme }) => theme.colors.surface20};
  width: 40%;
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing80} ${theme.spacing.spacing40}`};
`;

const Img = styled.div`
  padding-top: 45%;
  margin: ${({ theme }) =>
    `${theme.spacing.spacing40} ${theme.spacing.spacing40} ${theme.spacing.spacing80}`}
  background: ${({ theme }) => theme.colors.surface20};
`;

const Container = styled.div`
  min-height: 100vh;
`;

function Placeholder() {
  return (
    <Container>
      <Banner />
      <Title />
      <Img />
      <Img />
    </Container>
  );
}

export default memo(Placeholder);
