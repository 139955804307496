// libs
import { styled } from '@nykaa/ui-components';

// components
import SearchBar from 'shared/layouts/Homepage/HomePageMobile/components/SearchBar';

export const HeaderSearchBar = styled(SearchBar)<{ isWishlistEnabled: boolean }>`
  margin: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  height: 32px;

  ${({ isWishlistEnabled }) => {
    if (isWishlistEnabled) {
      return `width: calc(100% - 170px)`;
    } else {
      return `width: calc(100% - 96px);`;
    }
  }}
`;
