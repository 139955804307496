// libs
import React, { memo, useState, useEffect, useCallback } from 'react';
import get from 'lodash/get';

// contexts
import { useMobileActionContext } from 'shared/components/Header/MobileAction/Context';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// color
import { COLORS } from 'shared/styles/base';

// styles
import { Container, SearchIcon, Text } from './styles';

// defs
interface ISearchBarProps {
  onHidden?: VoidFunction;
  onVisible?: VoidFunction;
  className?: string;
  isAnimationEnabled: boolean;
  doAnimate?: boolean;
  keywordPrefix: string;
  placeholderKeywords: string[];
  staticPlaceholder: string;
  IOOptions?: IntersectionObserverInit;
  searchBarOnHeader?: boolean;
  isWishlistEnabled: boolean;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const DEFAULT_IO_OPTIONS = {};
const SEARCH = 'Search for products';
enum DIRECTION {
  INCREASE,
  DECREASE,
}

function SearchBar({
  onHidden = EMPTY_FUNC,
  onVisible = EMPTY_FUNC,
  className,
  isAnimationEnabled,
  keywordPrefix,
  placeholderKeywords,
  staticPlaceholder,
  doAnimate = false,
  IOOptions = DEFAULT_IO_OPTIONS,
  searchBarOnHeader = true,
  isWishlistEnabled,
}: ISearchBarProps) {
  const [isVisible, setIsVisible] = useState(true);
  const canAnimateText = isAnimationEnabled && doAnimate;
  const showSearchBar = get(useRemoteConfig('showSearchBar'), 'dweb', true);

  const searchBarStaticPlaceHolder =
    isWishlistEnabled && searchBarOnHeader ? SEARCH : staticPlaceholder;

  const [searchText, setSearchText] = useState(
    canAnimateText ? keywordPrefix : searchBarStaticPlaceHolder
  );
  const { showSearch } = useMobileActionContext();

  useEffect(() => {
    let interval: number;

    if (canAnimateText && isVisible) {
      let keywordIndex = 0;
      let wordIndex = 0;
      let wordDirection = DIRECTION.INCREASE;

      // simulate typing
      interval = window.setInterval(() => {
        const word = placeholderKeywords[keywordIndex];
        const wordChunk = word.substring(0, wordIndex);

        setSearchText(`${keywordPrefix} ${wordChunk}`);

        wordDirection === DIRECTION.INCREASE ? wordIndex++ : wordIndex--;

        // once we reached end of word - simulate backpress
        if (wordDirection === DIRECTION.INCREASE && wordIndex > word.length) {
          wordDirection = DIRECTION.DECREASE;
        }

        // once we done a circle of word move to next word
        if (wordDirection === DIRECTION.DECREASE && wordIndex < 0) {
          keywordIndex++;
          wordIndex = 0;
          wordDirection = DIRECTION.INCREASE;
        }

        // if we have reached end of array of words, reset to start
        if (keywordIndex >= placeholderKeywords.length) {
          keywordIndex = 0;
        }
      }, 150);
    }

    return () => {
      if (interval !== undefined) {
        window.clearInterval(interval);
      }
    };
  }, [canAnimateText, isVisible, keywordPrefix, placeholderKeywords]);

  const onSearchBarVisible = useCallback(() => {
    onVisible();
    setIsVisible(true);
  }, [onVisible]);

  const onSearchBarHidden = useCallback(() => {
    onHidden();
    setIsVisible(false);
  }, [onHidden]);

  if (!showSearchBar) return null;
  return (
    <Container
      as="button"
      type="button"
      onClick={showSearch}
      onVisible={onSearchBarVisible}
      onHidden={onSearchBarHidden}
      className={className}
      options={IOOptions}
      data-test="container"
      data-at="search-button"
    >
      <SearchIcon color={COLORS.BLACK_36} data-test="container-icon" />
      <Text data-test="search-text">{searchText}</Text>
    </Container>
  );
}

export default memo(SearchBar);
