// libs
import { connect } from 'react-redux';

// redux selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// helpers
import {
  isAnimationEnabled,
  getKeywordPrefix,
  getPlaceholerKeywords,
  getStaticPlaceholer,
} from 'shared/layouts/Homepage/HomePageMobile/helpers';

// component
import SearchBar from './SearchBar';

// constants
import { DEFAULT_CONFIG } from 'shared/layouts/Homepage/HomePageMobile/constants';

const mapStateToProps = (state: any) => {
  const searchConfig = getRemoteConfig(state, 'HPSearchConfig') || DEFAULT_CONFIG;

  return {
    isAnimationEnabled: isAnimationEnabled(searchConfig),
    keywordPrefix: getKeywordPrefix(searchConfig),
    placeholderKeywords: getPlaceholerKeywords(searchConfig),
    staticPlaceholder: getStaticPlaceholer(searchConfig),
  };
};

export default connect(mapStateToProps)(SearchBar);
