//Libraries
import React, { useEffect, memo } from 'react';
import { frontloadConnect } from 'react-frontload';

// components
import HomePageHeader from './components/Header';
import HomePageAPI from 'shared/layouts/Homepage/HomePageAPI';
import Placeholder from './components/Placeholder';
import Child from './Child';

// redux
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// contexts
import { useAppLayoutContext } from 'shared/layouts/AppLayout/AppLayoutContext';

// constants
import { APP_CONTAINER_CSS } from 'shared/styles/base';

// hooks
import useDealsPlatformRedirect from 'shared/hooks/useDealsPlatformRedirect';

function HomepageMobile() {
  const { setCSS } = useAppLayoutContext();

  const { mWebPageName } = useRemoteConfig('hp');
  const shouldRenderWithDealsPlatform = useDealsPlatformRedirect(mWebPageName);

  useEffect(() => {
    setCSS(APP_CONTAINER_CSS);
  }, [setCSS]);
  return (
    <>
      <HomePageHeader />
      <HomePageAPI
        pageName={mWebPageName}
        placeholder={<Placeholder />}
        shouldTransformForDealsPlatform={shouldRenderWithDealsPlatform}
      >
        {Child}
      </HomePageAPI>
    </>
  );
}

const fetchData = async ({ setHeaderOptions }: any) => {
  setHeaderOptions({
    showLogoOnLeft: false,
  });
};

export default frontloadConnect(fetchData)(memo(HomepageMobile));
