// libs
import React, { useEffect, memo } from 'react';

// contexts
import { useMobileLayoutContext } from './MobileLayoutContext';

// defs
type IMobileHeaderContentProps = React.PropsWithChildren<{}>;

function MobileHeaderContent({ children }: IMobileHeaderContentProps) {
  const { setHeaderContent } = useMobileLayoutContext();

  useEffect(() => {
    setHeaderContent(children);

    return () => {
      setHeaderContent();
    };
  }, [children, setHeaderContent]);

  return null;
}

export default memo(MobileHeaderContent);
