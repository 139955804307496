// libs
import { styled } from '@nykaa/ui-components';

// components
import IOElement from 'shared/components/IOElement';

// icons
import Search from '@nykaa/ui-components/Icons/search';

export const Container = styled(IOElement)`
  border: 0;
  background: transparent;
  display: flex;
  height: 40px;
  margin: ${({ theme }) => theme.spacing.spacing40};
  border-radius: ${({ theme }) => theme.borders.radius10};
  background-color: #ebecf0;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.spacing40};
  color: rgba(0, 0, 0, 0.36);
  width: calc(100% - 16px);
  flex-shrink: 0;
  flex-grow: 0;
`;

export const SearchIcon = styled(Search)`
  padding: 0 3px 0px 0px;
`;

export const Text = styled.span`
  margin-left: ${({ theme }) => theme.spacing.spacing20};
  width: calc(100% - 24px);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
  flex-grow: 0;
`;
