// libs
import { connect } from 'react-redux';

// redux selectors
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// helpers
import { isSearchBarEnabled } from 'shared/layouts/Homepage/HomePageMobile/helpers';
import { getWishlistEnabled } from 'shared/components/Wishlist/helper';

// component
import Experiment from './Experiment';

// constants
import { DEFAULT_CONFIG } from 'shared/layouts/Homepage/HomePageMobile/constants';

const mapStateToProps = (state: any) => {
  const searchConfig = getRemoteConfig(state, 'HPSearchConfig') || DEFAULT_CONFIG;
  const isWishlistEnabled = getWishlistEnabled(getRemoteConfig(state, 'wishlist')).mobile;

  return {
    isSearchBarEnabled: isSearchBarEnabled(searchConfig),
    isWishlistEnabled,
  };
};

export default connect(mapStateToProps)(Experiment);
