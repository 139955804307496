// libs
import React, { useEffect } from 'react';
import IOElement from 'shared/components/IOElement';
import { getExperimentVariant } from '@nykaa/experiment-sdk/client';

// analytics
import { onPageLoad } from 'shared/layouts/Homepage/analytics';

// components
import WidgetRendererMobile from 'shared/components/WidgetRendererSwitch/Mobile';
import MetaInfo from 'shared/components/AdPlatformWidgets/components/MetaInfo';
import GamificationNudge from 'shared/components/GamificationNudge';

// hooks
import useCsrSsrSplit from 'shared/hooks/useCsrSsrSplit';

// styles
import { ScrollToTop } from './styles';

//helpers
import { getMetaData } from 'shared/components/AdPlatformWidgets/helpers';

// defs
import { ILandingPageProps } from 'shared/components/AdPlatformWidgets/types';

// constants
import { GAMIFICATION_NUDGE, GAMIFICATION_NUDGE_VARIANT_A } from 'shared/components/GamificationNudge/constants';
interface IHomePageChild {
  homepageData: ILandingPageProps['landingPageData'];
  pageName: string;
}

const Child = ({ homepageData, pageName }: IHomePageChild) => {
  // hook
  const {
    pageFirstFoldData,
    pageBelowTheFoldData,
    showBelowTheFold,
    onVisible,
    eagerLoadingCount,
  } = useCsrSsrSplit(homepageData);
const { isEligible = false, variant } =
    getExperimentVariant(GAMIFICATION_NUDGE);
  const isGamificationEnabled = isEligible && variant === GAMIFICATION_NUDGE_VARIANT_A;
  
  useEffect(() => {
    onPageLoad({ NDNPageType: pageName, isGamificationEnabled: isGamificationEnabled });
  }, [pageName, isGamificationEnabled]);

  const metaData = getMetaData(homepageData);
  return (
    <>
      <MetaInfo metaData={metaData} />
      <WidgetRendererMobile
        landingPageData={pageFirstFoldData}
        pageName={pageName}
        eagerLoadingCount={eagerLoadingCount}
      />
      <IOElement onVisible={onVisible} once />

      {showBelowTheFold && (
        <>
          {isGamificationEnabled && <GamificationNudge isPadddingRequired={true} />}

          <WidgetRendererMobile
            landingPageData={pageBelowTheFoldData}
            pageName={pageName}
            eagerLoadingCount={eagerLoadingCount}
          />
        </>
      )}

      <ScrollToTop />
    </>
  );
};

export default Child;
